const {
  REACT_APP_GOOGLE_CLIENT_ID_LOGIN,
  REACT_APP_SERVER_URL,
  NODE_ENV,
  REACT_APP_CLIENT_ID,
  REACT_APP_REDIRECT_URL,
  REACT_APP_LOGROCKET_ID,
  REACT_APP_HUBSPOT_EMBED_LINK,
  REACT_APP_RECAPTCHA_SITE_KEY,
  REACT_APP_SCOPE_GOOGLE_API,
  REACT_APP_CALENDAR_GOOGLE_CLIENT_ID,
  REACT_APP_CUSTOMER_OWNER_FIELD_ACCOUNT_ID,
  REACT_APP_GOOGLE_TAG_MANAGER_ID,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_CALLBACK_URL,
  REACT_APP_AUTH0_REDIRECT_URL,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_FREE_PLAN_MONTHLY,
  REACT_APP_FREE_PLAN_YEARLY,
  REACT_APP_FREE_PLAN_PRICE_IDS,
} = process.env

export const config = {
  SERVER_URL: REACT_APP_SERVER_URL,
  NODE_ENV: NODE_ENV,
  GOOGLE_CLIENT_ID: REACT_APP_GOOGLE_CLIENT_ID_LOGIN,
  GOOGLE_CALENDAR_CLIENT_ID: REACT_APP_CALENDAR_GOOGLE_CLIENT_ID,
  CLIENT_ID: REACT_APP_CLIENT_ID,
  REDIRECT_URL: REACT_APP_REDIRECT_URL,
  SCOPE:
    'crm.schemas.deals.read%20automation%20crm.objects.owners.read%20tickets%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.schemas.contacts.read%20crm.objects.deals.write%20crm.objects.contacts.read%20crm.schemas.companies.read',
  LOGROCKET_ID: REACT_APP_LOGROCKET_ID as string,
  HUBSPOT_EMBED_LINK: REACT_APP_HUBSPOT_EMBED_LINK,
  RECAPTCHA_SITE_KEY: REACT_APP_RECAPTCHA_SITE_KEY,
  SCOPE_GOOGLE_API: REACT_APP_SCOPE_GOOGLE_API,
  FIELD_USER_ROUTER: REACT_APP_CUSTOMER_OWNER_FIELD_ACCOUNT_ID,
  GOOGLE_TAG_MANAGER_ID: REACT_APP_GOOGLE_TAG_MANAGER_ID ?? 'GTM-MS9XJG6',
  AUTH0_DOMAIN: REACT_APP_AUTH0_DOMAIN as string,
  AUTH0_CLIENT_ID: REACT_APP_AUTH0_CLIENT_ID as string,
  AUTH0_CALLBACK_URL: REACT_APP_AUTH0_CALLBACK_URL as string,
  AUTH0_REDIRECT_URL: REACT_APP_AUTH0_REDIRECT_URL as string,
  AUTH0_AUDIENCE: REACT_APP_AUTH0_AUDIENCE as string,
  FREE_PLAN_MONTHLY: REACT_APP_FREE_PLAN_MONTHLY as string,
  FREE_PLAN_YEARLY: REACT_APP_FREE_PLAN_YEARLY as string,
  FREE_PLAN_PRICE_IDS: REACT_APP_FREE_PLAN_PRICE_IDS,
}

export const title = 'Routera'

export enum PERCENTAGE_MODEL {
  PERCENTAGE = 'percentage',
  ROUNDROBIN = 'roundRobin',
  AIBASED = 'AiBased',
}

export const modelLabelMap: Record<PERCENTAGE_MODEL, string> = {
  [PERCENTAGE_MODEL.PERCENTAGE]: 'Percentage',
  [PERCENTAGE_MODEL.ROUNDROBIN]: 'Round Robin',
  [PERCENTAGE_MODEL.AIBASED]: 'AI Based',
}

export enum userRoles {
  TEAM_MEMBER = 'teamMember',
  ADMIN = 'admin',
}

export enum SOURCE_CONNECTION {
  webhook = 'webhook',
  hubspot = 'hubspot',
}
